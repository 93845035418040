.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiSnackbar-root {
  left: unset !important;
  right: 8px;
  display: flex;
  z-index: 1400;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 220px;
}

.MuiSnackbarContent-root {
  background-color: #899c42 !important;
  width: 100%;
  display: block !important;
}
@media screen and (min-width: 320px) {
  .MuiSnackbarContent-root {
    flex-grow: initial;
    min-width: 140px !important;
  }
}

@media screen and (min-width: 600px) {
  .MuiSnackbarContent-root {
    flex-grow: initial;
    min-width: 180px !important;
  }
}

.MuiSnackbarContent-action {
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
  padding: 0.4rem 0;
  width: 100% !important;
  display: block !important;
}
.theMessage {
  padding-left: 0.8rem;
  margin: 0;
}
.theFlexing {
  display: flex;
  width: 100%;
}
.theRowing {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.MuiSnackbarContent-message {
  padding: 0 !important;
}

.theCloseIcon {
  position: absolute;
  right: 10px;
}

/* .makeStyles-theGoogleLogin-9 {
  box-shadow: unset !important;
  border: 1px solid #878787;
  background-color: rgb(66, 133, 244) !important;
  color: rgb(255, 255, 255) !important;
  width: 240px;
  border: none;
  text-align: center;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px 0px;
  font-size: 1rem !important;
  display: block;
  border-radius: 1px;
  transition: background-color 0.218s ease 0s, border-color 0.218s ease 0s,
    box-shadow 0.218s ease 0s;
  cursor: pointer;
}

.makeStyles-theGoogleLogin-9 span {
  width: 100%;
} */

.kep-login-facebook{
  padding: 0.6rem 3rem !important;
  width: 100%;
  text-transform: none !important;
  font-weight: normal !important;
}

