.category_slick .slick-list {
  padding: 0 20% 0 0;
}
.theMobile {
  display: flex;
  overflow: scroll;
}
.theMobile ::-webkit-scrollbar {
  width: unset;
  height: 8px;
  background-color: transparent;
  border-radius: unset;
}
.theMobile ::-webkit-scrollbar-thumb {
  /* background-color: rgba(0, 0, 0, 0.5);
    border-radius: 30px; */
}

.theDesktop {
  display: inline-block;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}
.theDesktop::-webkit-scrollbar {
  width: 1px;
  height: 10px;
  background: transparent;
  margin: 10px;
}
.theDesktop::-webkit-scrollbar-thumb {
  background: #eaeaea;
}

.theNewArrival {
  display: flex;
  overflow: scroll;
}
.theNewArrival ::-webkit-scrollbar {
  width: unset;
  height: 100px;
  background-color: transparent;
  border-radius: unset;
}
.theNewArrival ::-webkit-scrollbar-thumb {
  /* background-color: rgba(0, 0, 0, 0.5);
    border-radius: 30px; */
}

.countdown_date {
  top: 6px;
  right: 0;
  left: unset;
}

.the2Line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 12px;
  color: #000;
  font-weight: 600;
  margin: 0 0 0.4rem 0;
}

/*.dialog-enter {
  left: -100%;
  transition: left 300ms linear;
}
.dialog-enter-active {
  left: 0;
}
.dialog-exit {
  left: 0;
  transition: left 300ms linear;
}
.dialog-exit-active {
  left: -100%;
}*/

.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(100px);
}
